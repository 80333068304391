.slider-card-container{
    position: relative;
    height: 70vh;
    width: 96vw;
    margin: 0 auto;
    padding-top: 1vh;
    padding-bottom: 1vh;
    overflow: hidden;
    white-space: nowrap;
}

.slider-card-container>img {
    position: absolute;
    height: 100%;
    width: 100%;
}

.slide-img {
    opacity: 0;
    transition: opacity 1.25s;
}

.show {
    opacity: 1;
}