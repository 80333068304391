/* @font-face {
  font-family: 'PlayfairDisplay', serif !important;
  src: url(../fonts/PlayfairDisplay.woff);
  src: url(../fonts/PlayfairDisplay.ttf) format('truetype');
  src: url(../fonts/Century.eot);
  src: url(../fonts/Century.eot?#iefix) format('embedded-opentype'),
      url(../fonts/Century.ttf) format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: 'PlayfairDisplay', serif;
  overflow-x: hidden;
  background-color: #ffff;
}

.card{
  border-radius: 4px;
  flex: 25%;
  /* width: 100%;
  height: 100%; */
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: default;
  align-items: center;
}

.container1{
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.card:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card h3{
  font-weight: 600;
}

.card img{
  position: center;
  max-width: 100%;
  max-height: 100%;
}

.card-2{
  margin-left: 10vh;
}

.card-1{
  padding: 18px 18px 0px 18px;
}

@media(max-width: 990px){
  .card{
    margin: 20px;
  }
}

.Photography{
  text-align: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 24px;
}

/* .photography-details{
  text-align: center;
  padding-left: 0rem;
  padding-right: 0rem;
  font-size: 16px;
} */

.AboutMe-divholder{
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(1vw, 1fr));
  grid-auto-rows: 3vw;
  grid-auto-flow: column dense;
  justify-content: center;
}

.div-item{
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  box-sizing: border-box;
  grid-column-start: auto;
  grid-row-start: auto;
  background-size: cover;
  background-position: center;
}
.div-item.photo{
  grid-row-end: span 3;
}

.div-item h1{
  justify-self: center;
}

.div-item .img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.scroll-button{
  position: fixed; 
  width: 100%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: black;
}

@media (max-width: 1000px){ /* MOBILE */
  .scroll-button{
    font-size: 2.25rem;
  }
}