nav {
    display: flex;
}

nav a {
    color: black;
    text-decoration: none;
}

.NavigationBar{
  background-color: #fff;
  border-bottom: 1px solid black;
  height: 5vh;
  
  justify-content: space-between;
}

.first-div{
    display: flex;
    align-items: flex-end;

    position: relative;
    left: 5vw;

    margin-bottom: 0.5vh;
}

.second-div{
    display: flex;
    align-items: flex-end;

    position: relative;

    right:  5vw;
    max-width: 50vw;
    max-height: 6vh;
}

.menu-nav {
    list-style: none;
    padding: 0;
}

.menu-nav>li {
    font-size: 28px;
}

.NavigationBar .toggle {
    display: flex;
    align-items: flex-end;

    position: relative;
    right: 0.01vw;

    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 10;
}

@media (max-width: 1000px){ /* MOBILE */
    .first-div{
        left: 3vw;
    }

    .second-div{
        right:  4vw;
    }
    .burger-close{
        width: 4vh;
        height: 4vh;
    }
    .burger-open{
        width: 4vh;
        height: 4vh;
    }
    .menu-nav {
        list-style: none;
        /* padding-top: 5vh; */

        position: fixed;
        display: flex;
        flex-direction: column;

        background: #fff;
        right: -100%;
        width: 100%;
        height: 100vh;
        top: 0;
        margin-top: 0;
        overflow: hidden;
        max-width: 75vw;
        z-index: 9;
        padding-top: 4vh;
        opacity: 0; 

        transition: right 500ms ease-in-out 150ms, opacity 600ms ease-in-out 150ms;
        /* text-align: left;
        align-items: center; */
        /* border-radius: 25px; */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &.show-menu{
            right: 0vw;
            opacity: 1;
            transition: right 600ms ease-in-out 0ms, opacity 600ms ease-in-out 0ms;
            
        }
        
        
    }

    .underlay{
        position: absolute;
        left: 0;
        /* top: 5vh; */
        width: 100%;
        height: 100%;
        overflow: hidden;
        /* border-radius: 10px; */
        
        opacity: 0;
        transition: opacity 600ms ease-in-out 50ms;
        &.show-menu{
            opacity: 1;
            transition: opacity 900ms ease-in-out 0ms;
        }
    }

    .underlay .underlay-image{
        position: absolute;
        
        width: 100%;
        height: 100%;
        background-size:cover;
        background-repeat: no-repeat;
        /* background-position: 0 50%; */

        
    }

    .underlay .underlay-colour{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /* background-color: #f8f6f2; */
        background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
        box-shadow: 10px 0 8px 10px white inset;
        opacity: 1;
    }

    .link-box{
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 100%;
        /* top: 3vh; */
        position: relative;
        /* align-items: center; */
        min-height: 5vh;
        /* float: left; */
        z-index: 2;
    }

    .centered-link-box{
        display: table-cell;
        width: 100%;
        position: relative;
        vertical-align: middle;
        opacity: 1!important;
        
    }

    .link-container{
        z-index: 2;
        display: flex;
        position: relative;
        left: 5vw;

        opacity: 0;
        transition: opacity 600ms ease-in-out 50ms;
        &.show-menu{
            opacity: 1;
            transition: opacity 900ms ease-in-out 0ms;
        }
        /* justify-content: center; */
        /* align-content: center;     */
    }

    .menu-nav>li {
        position: relative;

        font-size: 28px;
        font-weight: 0;
        font-style:normal;

        margin-top: 1vh;
        /* margin-bottom: 2vh; */
        height: 5vh;
        
        color: black;
        &.collection{
            font-size: 20px;
        }       
    }
    .img-logo{
        position: absolute;
        bottom: 5vh;
        align-self: center;

        opacity: 0;
        transition: opacity 600ms ease-in-out 50ms;
        &.show-menu{
            opacity: 1;
            transition: opacity 900ms ease-in-out 0ms;
        }
    }
}

@media (min-width: 1001px){ /* DESKTOP */
    .menu-nav {
        margin: 0;
        margin-bottom: 0.1vh;
    }
    
    .menu-nav>li {
        display: inline-block;
        margin-left: 7vh;
        position: relative;
        font-weight: 400;
    
        padding: 0;
    }
      
    .menu-nav>li:hover {
        color: black;
    }
    
    .menu-nav>li:before {
        
        content: '';
        display: block;
        height: 1px;
        background-color: black;
        position: absolute;
        bottom: 7.5px;
        width: 0%;
        transition: all ease-in-out 400ms;
    }
    
    .menu-nav>li:hover::before {
        width: 100%;
    }
}

.logo {
    width: 50%;
    height: 50%;

    
}

.logo>image {
    width: 100%;
    height: 100%;
}

@media (max-width: 700px){
    .logo {
        width: 55%;
        height: 55%;

        margin-bottom: 0.25vh;
    }
}