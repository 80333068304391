.photography{
    text-align: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 24px;
}

@media (max-width: 1000px){ /* MOBILE */
    .collection {
        position: relative;
        visibility: visible;

        display: flex;
        flex-direction: column;
        align-items: center;
        
        margin-top: 1vh;

        width: 100%;
        height: 20vh;
        margin-left: auto;
        margin-right: auto;

        overflow-x: hidden;
    }
    
    .overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .overlay .overlay-image{
        position: absolute;
        
        width: 100%;
        height: 100%;
        background-size:cover;
        background-repeat: no-repeat;
        /* background-position: 0 50%; */
    }

    .overlay .overlay-colour{
        position: absolute;
        /* left: 0;
        top: 0; */
        width: 100%;
        height: 100%;
        background-color: #f8f6f2;
        opacity: 0.2;
        transition: opacity 1s ease-out;
    }

    .overlay-colour.hovered{
        opacity: 0.5;
        transition: opacity 0.75s ease-in;
    }

    .logo-box{
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 80%;
        position: relative;
        /* align-items: center; */
        min-height: 5vh;
        /* float: left; */
        z-index: 2;
    }

    .centered-logo-box{
        display: table-cell;
        width: 100%;
        position: relative;
        vertical-align: middle;
        opacity: 1!important;
        
    }

    .logo-container{
        z-index: 2;
        display: flex;
        position: relative;
        justify-content: center;
        /* align-content: center;     */
    }


    .i3bris{
        transform: scale(0.7);
        left: 2vw;
        position: relative;
        align-self: center;
    }

    .ooc{
        /* height: 83px; */
        transform: scale(0.7);
        position: relative;
        align-self: center;
    }
}

@media (min-width: 1001px){ /* DESKTOP */
    .collection{
        position: relative;
        visibility: visible;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* width: 70%; */
        height: 56vh;
        margin-left: auto;
        margin-right: auto;

        margin-top: 2vh;
        /* left: 5vw; */
    }

    .overlay{
        position: absolute;
        left: 15vw;
        top: 0;
        width: 70%;
        height: 100%;
        overflow: hidden;
    }

    .overlay .overlay-image{
        position: absolute;
        
        width: 100%;
        height: 100%;
        /* background-size: cover; */
        background-repeat: no-repeat;
        background-position: 50% 0%;
    }

    .overlay .overlay-colour{
        position: absolute;
        /* left: 0;
        top: 0; */
        width: 100%;
        height: 100%;
        background-color: #f8f6f2;
        opacity: 0.2;
        transition: opacity 1s ease-out;
    }

    .overlay-colour.hovered{
        opacity: 0.5;
        transition: opacity 0.75s ease-in;
    }

    .logo-box{
        display: table;
        table-layout: fixed;
        width: 70%;
        height: 100%;
        position: relative;
        min-height: 5vh;
        float: left;
        left: 15vw;
        z-index: 2;
    }

    .centered-logo-box{
        display: table-cell;
        width: 100%;
        position: relative;
        vertical-align: middle;
        opacity: 1!important;
    }

    .logo-container{
        z-index: 2;

        margin: 0 auto;
        padding: 0;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;

        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 500px!important;

        justify-content: center;
    }
    .logo-container>a{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .logo-text{
        opacity: 0;
        font-size: 24px;
        color: black;
        text-decoration: none;
        text-align: center;
    }

    a:link{
        color: black;
        text-decoration: none;
    }

    .logo-text.un-hidden{
        opacity: 1;
        transition: opacity 0.75s ease-in;
        text-decoration: none;
    }
    .i3bris{
        left: 1vw;
        position: relative;
        align-self: center;
    }
    
    .ooc{
        /* display: inline-block; */
        /* transform: scale(0.95); */
        position: relative;
        align-self: center;
    }
}