.gallery-main{
  text-align: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 24px;
}

.gallery-header{
  display: flex;
  justify-content: center;

  align-items: center;
  height: 7.5vh;
  margin-bottom: 0.5vh;
}

.header-image {
  z-index: 2;
  /* width: 90%;
  height: 90%; */

  margin-top: 0.5vh;
  align-self: center;
}
.header-image.bris{
  margin-top: -1vh;
}

.header-image>image {
  width: 100%;
  height: 100%;
}

.gallery {
  display: grid;
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(29vw, 0fr));
  grid-auto-rows: 38vh;
  grid-auto-flow: row dense;
  justify-content: center;
}

@media (max-width: 1000px){
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(48vw, 0fr));
    grid-auto-rows: 16vh;
    justify-content: center;
  }
}

.gallery__item {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: #fff;
  grid-column-start: auto;
  grid-row-start: auto;
  color: #fff;
  background-size: cover;
  background-position: center;
  transition: transform 300ms ease-in-out 100ms;
  cursor: pointer;
  counter-increment: item-counter;
}

.gallery__item.vert{
  display: flex;
  grid-row-end: span 2;
  align-content: center;

  overflow: hidden;
}

.gallery__item.vert>img{
  width: 100%;
  height: 100%;
}

.gallery__item.horiz{
  display: flex;
  flex-direction: row;
  grid-row-end: span 1;

  margin: 0;
  padding: 0;
}

.gallery__item.horiz>img{
  width: 100%;
  height: 100%;
}

.gallery__item:hover{
  transform: scale(1.025);
}

.gallery__item:hover::after{
  opacity: 0.15;
}

@media only screen and (max-width: 768px) {
  .history {
     display: block;
  }
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btnClose {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  opacity: 0.3;
  color: black;
  z-index: 9999;
  transition: transform ease-in-out 0.2s;
}

.btnNext, .btnPrev{
  display: flex;
  position: fixed;
  cursor: pointer;
  background: white;
  opacity: 0.35;
  border-radius: 75%;
  justify-content: center;
  height: 5vh;
  width: 5vh;
  align-items: center;
  transition: transform ease-in-out 1s;
  transition: opacity ease-in-out 0.5s;
}

.sliderWrap .btnPrev {
  top: 50%;
  margin-right: 85vw;
}

.sliderWrap .btnNext {
  top: 50%;
  margin-left: 85vw;
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  transform: scale(1.1);
  opacity: 1;
}

.sliderWrap .btnPrev img{
  transform: translate(-2px, 0em)
}

.sliderWrap .btnNext img{
  transform: translate(2px, 0em)
}

.fullScreenImage {
  /* width: calc(93% - 100px); */
  height: calc(93% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.fullScreenImage .btnClose{
  position: absolute;
  top: 0px;
  right: 0px;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media (max-width: 480px){
  .gallery-main{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .gallery {
    grid-row-gap: 0.25rem;
    grid-column-gap: 0.25rem;

    margin: 0;
    padding: 0;
  }

  .gallery-header{

    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 4.5vh;
  }

  .header-image {
    width: 40%;
    margin-left: 3vw;
  }  

  .btnNext, .btnPrev{
    height: 4vh;
    width: 4vh;
  }
} 

@media (max-width: 1000px){ /* MOBILE */
  .fullScreenImage {
    width: calc(95% - 100px);
    /* height: calc(93% - 100px); */
    z-index: 999;
  }

}

@media (min-width: 1001px){ /* DESKTOP */

}