body {
    background-color: #f8f6f2;
}
.main-div{
    position: absolute;

    height: 100%;
    width: 100%;

    margin: 0 auto;
    /* overflow: hidden; */

    display: flex;
    justify-content: space-between;
}

@media (max-width: 1000px){ /* MOBILE */
    .main-div{
        flex-direction: column;
        height: 100%;

        display: flex;
        justify-content: flex-start;

        overflow-x:hidden;
    }

    .about-slide{
        position: relative;
        vertical-align: middle;
        
        width: 95%;
        height: 50%  - 10vh;

        left: 2.5vw;
        top: 1%;
    }
    .about-slide>h1{
        font-size: 28px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        
    }
    .about-slide>p{
        display: inline-block;
        font-size: 16px;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
    }
    .about-slide>br{
        width: 1%;
    }

    .images-div{
        /* margin-right: 12.5vw; */
        /* width: 83.75vw; */
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }
    
    .single-image{
        margin-top: 1.5vh;
    }
    
    .single-image>img{
        height: 100%;
        width: 100%;
    }

}

@media (min-width: 1001px){ /* DESKTOP */
    .about-slide{
        position: relative;
        vertical-align: middle;
        width: 35%;
        height: 85%;
        font-size: 18px;
        left: 5vw;
        top: 25vh;
    }
    
    .about-slide>h1{
        font-size: 60px;
        font-weight: 500;
    }
    
    .about-slide>p{
        font-size: 16px;
    }
    
    .images-div{
        margin-right: 12.5%;
        /* width: 83.75vw; */
        width: 41.875%;
    }
    
    .single-image{
        /* height: 56.9%; */
        /* height: 57%; */
        margin-top: 1.5vh;
    }
    
    .single-image>img{
        height: 100%;
        width: 100%;
    }
}